function scrollPosition(pageEl) {
	const modalElements = document.querySelectorAll('a.js-project-link')

	modalElements.forEach(mElement => {
		const mEl = mElement.getAttribute('data-modal')
		if (mEl == pageEl) {
			mElement.classList.add('active-modal')
			// Only for single page animations
			global.singleReverselElement = mElement
		}
	})
}
export default scrollPosition
