import React from 'react'
import Img from 'gatsby-image'

const RightImage = ({ modalData }) => {
	if (modalData.childWordPressAcfLeftTextRightImage !== null) {
		return (
			<section className="container right-image-content">
				{modalData.childWordPressAcfLeftTextRightImage !== null && (
					<div className="row">
						<div className="col-md-6 chapter-info">
							<div className="description">
								<h2 className="reveal">
									{
										modalData.childWordPressAcfLeftTextRightImage.content
											.section_title
									}
								</h2>
								<div
									className="reveal"
									dangerouslySetInnerHTML={{
										__html:
											modalData.childWordPressAcfLeftTextRightImage.content
												.text,
									}}
								/>
							</div>
						</div>
						<div className="col-md-6 chapter-image">
							{modalData.childWordPressAcfLeftTextRightImage.content
								.section_image !== null && (
								<Img
									fluid={
										modalData.childWordPressAcfLeftTextRightImage.content
											.section_image.localFile.childImageSharp.fluid
									}
									alt={
										modalData.childWordPressAcfLeftTextRightImage.content
											.section_image.alt_text
									}
								/>
							)}
						</div>
					</div>
				)}
			</section>
		)
	}
	return null
}

export default RightImage
