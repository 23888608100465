import React from 'react'
import Img from 'gatsby-image'

const Image = ({ modalData }) => {
	if (modalData.childWordPressAcfJustImage !== null) {
		return (
			<section className="container image-content">
				<div className="image-wrapper">
					<Img
						fluid={
							modalData.childWordPressAcfJustImage.section_image.localFile
								.childImageSharp.fluid
						}
						alt={modalData.childWordPressAcfJustImage.section_image.alt_text}
					/>
				</div>
			</section>
		)
	}
	return null
}

export default Image
