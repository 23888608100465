import React, { Component } from 'react'
import { connect } from 'react-redux'

import { graphql } from 'gatsby'
import { Link } from 'gatsby-plugin-modal-routing'
import {
	divReverseBoundingClientRect,
	imageBoundingClientRect,
} from '../actions/animationAction'
import { alignElements } from '../actions/alignAction'

import { unlock } from 'tua-body-scroll-lock'

import SEO from '../components/seo'
import Logo from '../images/logo.svg'
import ConditionalLayout from '../components/conditionalModal'
import Img from 'gatsby-image'

import TweenMax from 'gsap/TweenMax'
import scrollPosition from '../components/helpers/scrollPosition'
import modalAnimations from '../components/helpers/modalAnimations'

var scroll = require('scroll')

import AboutProject from '../fields/aboutProject'
import RightImage from '../fields/rightImage'
import LeftImage from '../fields/leftImage'
import Icons from '../fields/icons'
import Text from '../fields/text'
import Image from '../fields/image'

global.time = 0.5
class PostTemplate extends Component {
	constructor(props) {
		super(props)
		// reference to the DOM nodes
		this.projectIntro = null
		this.projectModal = null
		this.projectWrapper = null
		this.projectLogo = null
		this.projectTitle = null
		this.projectTitleFont = null
		this.dummyContainer = null
		this.projectBody = null
		this.projectImagesWrapper = null
		this.projectImage = null

		this.projectImageSrc = null
		this.projectContainer = null

		// reference to the animation
		this.tl = new TweenMax({ paused: true })
	}

	componentDidMount() {
		this.tl = new TimelineMax({})

		window.addEventListener(
			'popstate',
			function(event) {
				// The popstate event is fired each time when the current history entry changes.
				//console.log('bla')
			},
			false
		)

		// Media queries
		var breakpoints = {
			smallDesktop: 1440,
			laptop: 1199,
			mobile: 568,
		}
		if (window.innerWidth > breakpoints.laptop) {
			var paddingRightContainer = 120
			var dummyContainerHeight = '7vh'
		} else if (window.innerWidth > breakpoints.mobile) {
			var paddingRightContainer = 60
			var dummyContainerHeight = 0
		} else if (window.innerWidth <= breakpoints.mobile) {
			var paddingRightContainer = 24
			var dummyContainerHeight = 0
		}
		if (window.innerWidth > breakpoints.smallDesktop) {
			var dummyContainerHeight = '15vh'
		}
		const { divRect } = this.props.animationData

		var largeScreen = window.matchMedia('(min-width:1140px)')
		var lTabletMax = window.matchMedia('(max-width:1024px)')
		var lTabletMin = window.matchMedia('(min-width:769px)')
		var tabletMax = window.matchMedia('(max-width: 768px)')
		var tabletMin = window.matchMedia('(min-width: 569px)')
		var mobileMax = window.matchMedia('(max-width: 568px)')
		var mobileMin = window.matchMedia('(min-width: 360px)')
		var sMobile = window.matchMedia('(max-width: 359px)')

		const pageEl = this.props.data.wordpressWpProject.slug
		scrollPosition(pageEl)

		/***********************************
		 * Align for single page modals
		 ***********************************/
		if (!document.getElementsByClassName('ReactModal__Content')[0]) {
			const testWrap = document.getElementsByClassName('projects-list')[0]
				.childNodes[0].childNodes
			var alignIndex

			testWrap.forEach((element, i) => {
				if (element.childNodes[0].classList.contains('active-modal')) {
					alignIndex = i + 1
					this.props.divReverseBoundingClientRect(
						element.getBoundingClientRect()
					)
					this.props.imageBoundingClientRect(
						element.childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[1].getBoundingClientRect()
					)
				}
			})
			const mImageRect = this.projectImagesWrapper

			this.tl
				.set(
					this.projectContainer,
					{
						position: 'fixed',
						overflowY: 'auto',
						height: '100%',
					},
					0
				)
				.set(
					this.projectImagesWrapper,
					{
						top: mImageRect.offsetTop,
						marginLeft: 'auto',
					},
					0
				)
			if (alignIndex & 1) {
				this.tl
					.set(
						this.projectImagesWrapper,
						{ marginLeft: 0, textAlign: 'left', left: 0 },
						0
					)
					.set(this.projectLogo, { marginRight: 0, left: -35 }, 0)
					.set(this.projectTitle, { left: '38%', right: 'auto' }, 0)
			}
			this.props.alignElements(alignIndex)
		}

		/****************************************************************************
		 * Calculate scroll position and passing value to ConditionalModal.js
		 ***************************************************************************/

		//	scrollAnimations()

		/********************************
		 * Tween Max Aimation on start
		 ********************************/
		const rightAlign = document.getElementsByClassName('right-align')[0]
		const projectModal = document.getElementsByClassName('project-modal')[0]
		projectModal.classList.add('overflow-hidden')

		if (lTabletMax.matches && lTabletMin.matches) {
			var introImagesWidth = '68%' //max-width:1400px
		} else if (tabletMax.matches && tabletMin.matches) {
			if (projectModal.classList.contains('desktop')) {
				var introImagesWidth = '100%'
			} else {
				var introImagesWidth = '80%'
			}
		} else if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
			var introImagesWidth = '100%'
		} else {
			var introImagesWidth = '41.814%' //max-width:1400px
		}

		var introImagesWidthReverse = divRect.width

		/****************************************
		 * Check if page is modal / else single page
		 ****************************************/
		if (document.getElementsByClassName('ReactModal__Content')[0]) {
			// Animation start
			this.tl = new TimelineMax({
				onComplete: this.onComplete,
				onReverseComplete: this.onReverseComplete,
			})
			const tlModal = this.tl

			if (
				this.props.animationState.isAnimating ||
				this.props.animationState.play !== false
			) {
				/****************************************
				 * animations function and parameters
				 ****************************************/
				const projectIntro = this.projectIntro
				const projectModalRef = this.projectModal
				const projectWrapper = this.projectWrapper
				const projectLogo = this.projectLogo
				const projectTitle = this.projectTitle
				const projectTitleFont = this.projectTitleFont
				const dummyContainer = this.dummyContainer
				const projectBody = this.projectBody
				const projectImagesWrapper = this.projectImagesWrapper
				const projectImage = this.projectImage

				modalAnimations(
					rightAlign,
					paddingRightContainer,
					dummyContainerHeight,
					introImagesWidth,
					introImagesWidthReverse,
					largeScreen,
					lTabletMax,
					lTabletMin,
					tabletMax,
					tabletMin,
					mobileMax,
					mobileMin,
					sMobile,
					projectModal,
					tlModal,
					projectIntro,
					projectModalRef,
					projectWrapper,
					projectLogo,
					projectTitle,
					projectTitleFont,
					dummyContainer,
					projectBody,
					projectImagesWrapper,
					projectImage,
					divRect
				)
			} else {
				// console.log('nesto else')
				// console.log(this.props)

				const nav = document.getElementsByClassName('modal-navigation')[0]
				nav.classList.add('modal-open')
				this.tl.set(this.dummyContainer, { height: dummyContainerHeight }, 0)
			}
		} else {
			/********************************
			 * Single page animations
			 ********************************/
			//console.log('animations single page')

			setTimeout(function() {
				const nav = document.getElementsByClassName('modal-navigation')[0]
				nav.classList.add('modal-open')
			}, 500)
			document
				.getElementsByClassName('project-footer')[0]
				.setAttribute('style', 'display:block')
			document.body.classList.add('no-scroll')
			const projectModal = document.getElementsByClassName('project-modal')[0]
			projectModal.classList.add('overflow-auto')

			//Reverse h for responsive needed
			const reverseHeigth = '62vh'
			const paddingReverse = paddingRightContainer * 2 + 'px'

			this.tl = new TimelineMax({
				onComplete: this.onCompleteSingle,
				onReverseComplete: this.onReverseCompleteSingle,
			})
			this.tl
				.set(
					this.projectImagesWrapper,
					{
						position: 'absolute',
						left: 0,
						right: '0',
						padding: 'inherit',
						margin: 'auto',
						width: '100%',
					},
					0
				)

				.set(this.dummyContainer, { height: reverseHeigth }, 0)

			//console.log(rightAlign)
			//ne radi ovako

			if (rightAlign) {
				this.tl.set(
					this.projectLogo,
					{
						marginRight: 'initial',
						left: '-30vh',
					},
					0
				)
			} else {
				this.tl.set(
					this.projectLogo,
					{
						marginLeft: 'initial',
						right: '-30vh',
					},
					0
				)
			}
			const bodyRect = document.body.getBoundingClientRect()
			const elemenPosition = singleReverselElement.getBoundingClientRect()
			const singleOffset =
				elemenPosition.top - elemenPosition.height / 2 - bodyRect.top
			window.scrollTo(0, singleOffset)
		}
	}
	componentDidUpdate() {
		/********************************
		 * Tween Max Aimation reverse
		 ********************************/
		if (this.props.animationState.reverse === true) {
			//check for scrolling inside modal and scroll to top animation
			const page = document.getElementsByClassName('project-modal')[0]
			const position = page.scrollTop
			if (position < 700) {
				scroll.top(page, 0, { duration: 50 })
				setTimeout(() => reverseAimationSinglePage(), 50)
			} else if (position > 3500) {
				scroll.top(page, 0, { duration: 100 })
				setTimeout(() => reverseAimationSinglePage(), 700)
			} else {
				scroll.top(page, 0, { duration: 100 })
				setTimeout(() => reverseAimationSinglePage(), 100)
			}

			const reverseAimationSinglePage = () => {
				if (document.getElementsByClassName('ReactModal__Content')[0]) {
					this.tl.reverse().timeScale(1.2)
				}
			}

			const nav = document.getElementsByClassName('modal-navigation')[0]
			const projectModal = document.getElementsByClassName('project-modal')[0]
			const projectFooter = document.getElementsByClassName('project-footer')[0]
			nav.classList.remove('modal-open')
			projectModal.classList.remove('overflow-auto')
			projectModal.classList.add('overflow-hidden')
			projectFooter.classList.remove('reveal')

			// Reverse SINGLE PAGE
			if (!document.getElementsByClassName('ReactModal__Content')[0]) {
				//	console.log('reverse start single')
				const reverseDiv = this.props.animationData.divReverseRect
				const reverseImage = this.props.animationData.imageRect
				document
					.getElementsByClassName('project-footer')[0]
					.setAttribute('style', 'display:none')

				this.tl
					.to(
						[this.projectModal, this.projectContainer],
						time,
						{
							left: reverseDiv.left + 12,
							top: reverseDiv.top - window.pageYOffset,
							width: reverseDiv.width - 24,
							height: reverseDiv.height,
						},
						0
					)
					.to(
						this.projectContainer,
						time,
						{
							overflow: 'hidden',
						},
						0
					)
					// .to(
					// 	this.projectContainer,
					// 	time,
					// 	{
					// 		autoAlpha: 0,
					// 	},
					// 	this.tl.duration() * 0.5
					// )
					.to(
						this.projectImage,
						time,
						{
							width: reverseImage.width,
							height: reverseImage.height,
						},
						0
					)
					.to(
						this.projectImageSrc.imageRef.current,
						time,
						{
							width: reverseImage.width,
							objectPosition: 'center right',
							left: 0,
						},
						0
					)
					.to(
						[this.projectIntro, this.projectImagesWrapper],
						time,
						{
							width: reverseDiv.width - 24,
							height: reverseDiv.height,
							padding: 'inherit',
						},
						0
					)
					.to(
						this.projectLogo,
						time,
						{
							width: 54,
						},
						0
					)
					.to(
						this.projectTitle,
						time,
						{
							autoAlpha: 0,
						},
						0
					)
					.to(
						this.projectWrapper,
						time,
						{
							padding: 0,
						},
						0
					)
					.to(
						this.dummyContainer,
						time,
						{
							height: 0,
						},
						0
					)
			}
		}
	}

	onContactClose = e => {
		document.body.classList.remove('no-scroll')
	}

	onComplete = () => {
		/********************************
		 * Tween Max on complete
		 ********************************/
		if (this.props.animationState.reverse !== true) {
			//	window.scrollTo(0, offset)
			//	console.log('on complete')

			document.body.classList.add('no-scroll')

			const nav = document.getElementsByClassName('modal-navigation')[0]
			const projectModal = document.getElementsByClassName('project-modal')[0]
			const projectFooter = document.getElementsByClassName('project-footer')[0]
			nav.classList.add('modal-open')
			projectModal.classList.add('overflow-auto')
			projectModal.classList.remove('overflow-hidden')
			projectFooter.classList.add('reveal')
			//Footer bug fix
			// document.getElementsByClassName('site-footer')[0].classList.add('z-index')
			// document
			// 	.getElementById('main')
			// 	.setAttribute('style', 'margin-bottom: 0; padding-bottom: 225px')
		} else {
			document.body.classList.remove('no-scroll')
		}
	}

	onReverseComplete = () => {
		const targetElement = document.body
		unlock(targetElement)
		document
			.getElementsByClassName('site-footer')[0]
			.classList.remove('padding-right')
	}

	render() {
		if (this.props.alignState.align > 0) {
			var alignSwitch = this.props.alignState.align
		}

		//	Set css style for modal
		const { divRect } = this.props.animationData
		if (this.props.animationState.reverse !== true) {
			var divStyle = {
				bottom: divRect.bottom,
				height: divRect.height,
				left: divRect.left,
				right: divRect.right,
				top: divRect.top,
				width: divRect.width,
				x: divRect.x,
				y: divRect.y,
			}
		}

		const hiddenStyle = {
			display: 'none',
		}
		//console.log(this.props)

		/********************************
		 * Data from GraphQl / Sorting
		 ********************************/
		const project = this.props.data.wordpressWpProject
		const options = this.props.data.wordpressAcfOptions.options
		const { menu_order } = project

		const condition = project.acf.mobile_or_desktop.toLowerCase()
		const pagination = this.props.data.allWordpressWpProject
		const nextProject = pagination.edges.filter(data => {
			if (data.node.menu_order == menu_order + 1) {
				return data !== undefined
			} else if (menu_order == 0) {
				if (data.node.menu_order == menu_order + 1) {
					return data !== undefined
				}
				if (data.node.menu_order == menu_order + 2) {
					return data !== undefined
				}
			}
		})

		return (
			<ConditionalLayout>
				<SEO
					title={project.title}
					keywords={[`portfolio`, `ux`, `ui`, `design-system`, `prototyping`]}
				/>
				<div
					style={divStyle}
					ref={div => (this.projectModal = div)}
					className={
						'project-modal ' +
						condition +
						(alignSwitch & (1 == 1) ? ' left-align' : ' right-align')
					}
					data-modal-open={project.slug}
				>
					<div
						ref={div => (this.projectContainer = div)}
						className="project-container js-project-container"
						data-modal-open={project.slug}
					>
						<div ref={div => (this.projectIntro = div)} id="project-intro">
							<section className="project-intro">
								<div
									ref={div => (this.projectWrapper = div)}
									id="intro-animation"
									className="container intro-wrapper"
								>
									<div
										ref={div => (this.dummyContainer = div)}
										className="dummyContainer"
									/>
									<div
										ref={div => (this.projectImagesWrapper = div)}
										className="intro-images"
									>
										{project.acf.project_logo_image !== null && (
											<div
												className="project-logo-wrapper"
												ref={div => (this.projectLogo = div)}
											>
												<Img
													fluid={
														project.acf.project_logo_image.localFile
															.childImageSharp.fluid
													}
													alt={project.acf.project_logo_image.alt_text}
													className="project-logo"
												/>
											</div>
										)}
										{project.featured_media !== null && (
											<div
												ref={div => (this.projectImage = div)}
												className="project-image-wrapper"
											>
												<Img
													ref={div => (this.projectImageSrc = div)}
													fixed={
														project.featured_media.localFile.childImageSharp
															.fixed
													}
													alt={project.featured_media.alt_text}
													className="image-responsive project-image"
												/>
											</div>
										)}
									</div>
									<div
										ref={div => (this.projectTitle = div)}
										className="project-title"
									>
										<h1 ref={div => (this.projectTitleFont = div)}>
											{project.title}
										</h1>
									</div>
								</div>
							</section>
						</div>
						<div ref={div => (this.projectBody = div)} id="project-body">
							<AboutProject signatureData={options} modalData={project} />
							<RightImage modalData={project} />
							<LeftImage modalData={project} />
							<Icons modalData={project} />
							<Text modalData={project} />
							<Image modalData={project} />
							{project.acf.about_project.links !== null && (
								<div className="container bottom-links">
									<div className="row">
										{project.acf.about_project.links.map(section => (
											<div key={section.link.title} className="col-12 links">
												<a
													href={section.link.url}
													target="_blank"
													rel="noopener"
												>
													{section.link.title}
												</a>
											</div>
										))}
									</div>
								</div>
							)}
						</div>
						<section
							style={hiddenStyle}
							className="container-fluid project-footer"
						>
							<div className="container">
								<div className="row">
									<div className="col-12 footer-wrapper">
										<div className="footer-work">
											<span>next project</span>
											{nextProject.length > 0 ? (
												<Link
													className="projects-link"
													to={'/projects/' + nextProject[0].node.slug}
													asModal
												>
													{nextProject[0].node.title}
												</Link>
											) : (
												<Link
													className="projects-link"
													to="/projects/"
													onClick={this.onContactClose}
												>
													projects
												</Link>
											)}
										</div>
										<div className="footer-bottom">
											<Link className="navbar-brand" to="/">
												<Logo />
											</Link>
											<Link className="contact-link" to="/say-hello" asModal>
												/say hello
											</Link>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>
				</div>
			</ConditionalLayout>
		)
	}
}

const mapStateToProps = state => ({
	animationData: state.animationData,
	animationState: state.animationState,
	alignState: state.alignState,
})

export default connect(
	mapStateToProps,
	{
		divReverseBoundingClientRect,
		imageBoundingClientRect,
		alignElements,
	}
)(PostTemplate)

export const pageQuery = graphql`
	query($id: String!) {
		allWordpressWpProject {
			edges {
				node {
					id
					slug
					title
					menu_order
				}
			}
		}
		wordpressAcfOptions {
			options {
				signature {
					role
					email_group {
						email
						text
					}
				}
			}
		}
		wordpressWpProject(id: { eq: $id }) {
			title
			id
			slug
			tags {
				id
				name
			}
			menu_order
			acf {
				mobile_or_desktop
				project_logo_image {
					alt_text
					localFile {
						childImageSharp {
							fluid(maxWidth: 85, quality: 100) {
								...GatsbyImageSharpFluid_tracedSVG
							}
						}
					}
				}
				about_project {
					title
					subtitle
					links {
						link {
							title
							url
						}
					}
				}
			}
			childWordPressAcfLeftTextRightImage {
				content {
					section_title
					text
					section_image {
						alt_text
						localFile {
							childImageSharp {
								fluid(maxWidth: 576, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			childWordPressAcfRightTextLeftImage {
				content {
					section_title
					text
					section_image {
						alt_text
						localFile {
							childImageSharp {
								fluid(maxWidth: 576, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			childWordPressAcfCustomIconSet {
				content {
					section_title
					text
					icons_image {
						alt_text
						localFile {
							childImageSharp {
								fluid(maxWidth: 576, quality: 100) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
			childWordPressAcfJustText {
				section_title
				section_text
			}
			childWordPressAcfJustImage {
				section_image {
					alt_text
					localFile {
						childImageSharp {
							fluid(maxWidth: 1440, quality: 100) {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
			featured_media {
				source_url
				alt_text
				localFile {
					childImageSharp {
						fixed(width: 669, quality: 100) {
							...GatsbyImageSharpFixed_tracedSVG
						}
					}
				}
			}
		}
	}
`
