function modalAnimations(
	rightAlign,
	paddingRightContainer,
	dummyContainerHeight,
	introImagesWidth,
	introImagesWidthReverse,
	largeScreen,
	lTabletMax,
	lTabletMin,
	tabletMax,
	tabletMin,
	mobileMax,
	mobileMin,
	sMobile,
	projectModal,
	tlModal,
	projectIntro,
	projectModalRef,
	projectWrapper,
	projectLogo,
	projectTitle,
	projectTitleFont,
	dummyContainer,
	projectBody,
	projectImagesWrapper,
	projectImage,
	divRect
) {
	tlModal
		.to(projectModalRef, time, {
			left: '0',
			top: '0',
			width: '100%',
			height: '100%',
		})
		.set(projectWrapper, { marginLeft: 'auto', marginRight: 'auto' }, 0)
		.from(projectWrapper, time, { marginLeft: 0, marginRight: 0 }, 0)
		.fromTo(
			projectImagesWrapper,
			time,
			{ width: introImagesWidthReverse },
			{ width: introImagesWidth },
			0
		)
		.fromTo(
			projectWrapper,
			time,
			{ paddingRight: '0', paddingLeft: '0' },
			{
				paddingRight: paddingRightContainer,
				paddingLeft: paddingRightContainer,
			},
			0
		)

		.fromTo(
			dummyContainer,
			time,
			{ height: '0' },
			{ height: dummyContainerHeight },
			0
		)
		.fromTo(projectBody, time, { autoAlpha: 0 }, { autoAlpha: 1 }, 0.6)

	/******************************
	 * Logo / Title media queries
	 *****************************/
	if (lTabletMax.matches && lTabletMin.matches) {
		tlModal
			.fromTo(
				projectLogo,
				time,
				{ right: 35, top: logoRectTop, width: 35 },
				{ right: 0, top: 100, width: 81 },
				0
			)
			.fromTo(
				projectTitle,
				time,
				{ paddingBottom: 0, paddingTop: 0, bottom: 0 },
				{ paddingBottom: 20, paddingTop: 20, bottom: 20 },
				0
			)
			.from(projectTitle, time, { x: -350 }, 0)
			.fromTo(
				projectTitle,
				time,
				{ opacity: 0 },
				{ opacity: 1, display: 'block' },
				0
			)

		if (rightAlign && projectModal.classList.contains('desktop')) {
			tlModal.fromTo(projectLogo, time, { left: 35 }, { left: 0 }, 0)
		}
	} else if (tabletMax.matches && tabletMin.matches) {
		tlModal.from(projectImage, time, { bottom: 0 }, 0)
		if (rightAlign && projectModal.classList.contains('desktop')) {
			tlModal.fromTo(projectLogo, time, { left: 35 }, { right: 0 }, 0)
		} else {
			tlModal.fromTo(projectLogo, time, { left: 25 }, { right: 0 }, 0)
		}
		tlModal
			.fromTo(
				projectLogo,
				time,
				{ right: 35, top: logoRectTop, width: 60 },
				{ right: 0, top: 35, width: 81, top: 135 },
				0
			)
			.fromTo(
				projectTitle,
				time,
				{ paddingBottom: 20, paddingTop: 20, bottom: 48 },
				{ paddingBottom: 20, paddingTop: 20, bottom: 20 },
				0
			)
			.fromTo(
				projectTitleFont,
				time,
				{ fontSize: '2rem' },
				{ fontSize: '1.8rem' },
				0
			)

		if (rightAlign) {
			tlModal.from(projectTitle, time, { x: -265, y: 8, paddingLeft: 20 }, 0)
		}
	} else if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
		// if regular mobile only
		if (mobileMax.matches && mobileMin.matches) {
			tlModal.fromTo(projectLogo, time, { right: 20 }, { right: 0 }, 0)
			if (rightAlign && !projectModal.classList.contains('desktop')) {
				tlModal
					.from(projectTitle, time, { x: -81, y: -40, paddingLeft: 15 }, 0)
					.fromTo(projectLogo, time, { left: 10 }, { left: 0 }, 0)
					.fromTo(
						projectTitle,
						time,
						{
							paddingLeft: 15,
							paddingRight: 15,
							paddingBottom: 0,
							paddingTop: 0,
							bottom: 0,
						},
						{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
						0
					)
			}

			if (rightAlign && projectModal.classList.contains('desktop')) {
				tlModal
					.fromTo(
						projectTitle,
						time,
						{
							paddingLeft: 15,
							paddingRight: 15,
							paddingBottom: 0,
							paddingTop: 0,
							bottom: 48,
						},
						{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
						0
					)
					.from(
						projectTitle,
						time,
						{ x: -69, y: 8, paddingRight: 15, paddingLeft: 15 },
						0
					)
					.fromTo(projectLogo, time, { left: 10 }, { left: 0 }, 0)
			} else {
				tlModal.fromTo(
					projectTitle,
					time,
					{
						paddingLeft: 15,
						paddingRight: 15,
						paddingBottom: 0,
						paddingTop: 0,
						bottom: 48,
					},
					{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
					0
				)
			}
		}
		//if small mobile only
		if (sMobile.matches) {
			tlModal
				.fromTo(
					projectLogo,
					time,
					{ top: 20, right: 10 },
					{ top: 0, right: 0 },
					0
				)
				.fromTo(
					projectTitle,
					time,
					{
						paddingLeft: 15,
						paddingRight: 15,
						paddingBottom: 0,
						paddingTop: 0,
						bottom: 48,
					},
					{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
					0
				)
			if (rightAlign && !projectModal.classList.contains('desktop')) {
				tlModal
					.fromTo(
						projectTitle,
						time,
						{
							paddingLeft: 15,
							paddingRight: 15,
							paddingBottom: 0,
							paddingTop: 0,
							bottom: 48,
						},
						{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
						0
					)
					.from(
						projectTitle,
						time,
						{ x: -69, y: 8, paddingRight: 15, paddingLeft: 15 },
						0
					)
			}

			if (rightAlign && projectModal.classList.contains('desktop')) {
				tlModal
					.fromTo(
						projectTitle,
						time,
						{
							paddingLeft: 15,
							paddingRight: 15,
							paddingBottom: 0,
							paddingTop: 0,
							bottom: 48,
						},
						{ paddingBottom: 12, paddingTop: 12, bottom: 20 },
						0
					)
					.from(
						projectTitle,
						time,
						{ x: -69, y: 8, paddingRight: 15, paddingLeft: 15 },
						0
					)
					.fromTo(projectLogo, time, { top: 68, left: 10 }, { left: 0 }, 0)
			}
		}
		tlModal
			.fromTo(projectLogo, time, { width: 30 }, { width: 81 }, 0)
			.fromTo(projectLogo, time, { top: 35 }, { top: 90 }, 0)

		tlModal
			.fromTo(
				projectTitleFont,
				time,
				{ fontSize: '1.5rem' },
				{ fontSize: '1.6rem' },
				0
			)
			.from(projectImage, time, { bottom: 0 }, 0)
	} else {
		tlModal
			.fromTo(
				projectLogo,
				time,
				{ top: logoRectTop, right: logoRectTop, width: 54 },
				{ top: 0, right: 0, width: 81 },
				0
			)
			.from(projectImage, time, { bottom: -4 }, 0)
			.from(projectTitle, time, { x: -350 }, 0)
			.fromTo(
				projectTitle,
				time,
				{ opacity: 0 },
				{ opacity: 1, display: 'block' },
				0
			)
	}
	/********************************
	 * Mobile project left align
	 ********************************/
	if (!rightAlign && !projectModal.classList.contains('desktop')) {
		// Large Tablets
		if (lTabletMax.matches && lTabletMin.matches) {
			tlModal
				.fromTo(projectImage, time, { x: 26, y: 110 }, { x: 0, y: 0 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 200 },
					0
				)
		}

		// Tablets
		if (tabletMax.matches && tabletMin.matches) {
			tlModal
				.fromTo(projectImage, time, { x: 25, y: 4 }, { x: 0, y: 4 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 100 },
					0
				)
		}

		// Mobile
		if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
			tlModal
				.fromTo(projectImage, time, { x: 0, y: 4 }, { x: -15, y: 4 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 60 },
					0
				)
		}
	}

	/********************************
	 * Mobile project right align
	 ********************************/
	if (rightAlign && !projectModal.classList.contains('desktop')) {
		tlModal.fromTo(
			projectImagesWrapper,
			time,
			{ marginLeft: 0 },
			{ marginLeft: 0 },
			0
		)
		// Large Screens
		if (largeScreen.matches) {
			console.log('large')

			tlModal
				.fromTo(projectImagesWrapper, time, { x: 0 }, { xPercent: 139 }, 0)
				.fromTo(projectImage, time, { x: 2, y: -1 }, { x: 0, y: 0 }, 0)
		}
		// large Tablets
		if (lTabletMax.matches && lTabletMin.matches) {
			tlModal
				.fromTo(projectImagesWrapper, time, { x: 0 }, { xPercent: 105 }, 0)
				.fromTo(projectLogo, time, { left: 35 }, { left: 0 }, 0)
				.fromTo(projectImage, time, { x: 0, y: 110 }, { x: 0, y: 0 }, 0)
				.fromTo(projectImage, time, { x: 26, y: 110 }, { x: 0, y: 0 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 200 },
					0
				)
		}

		if (tabletMax.matches && tabletMin.matches) {
			tlModal
				.fromTo(projectImagesWrapper, time, { x: 10 }, { xPercent: 33 }, 0)
				.fromTo(projectImage, time, { y: 4 }, { y: 4 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 100 },
					0
				)
		}

		if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
			tlModal
				//	.fromTo(projectImagesWrapper, time, { x: -10 }, { x: 0 }, 0)
				.fromTo(projectImage, time, { x: -30, y: 4 }, { x: 0, y: 4 }, 0)
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 100 },
					0
				)
		}
	}

	/********************************
	 * Desktop project left align
	 ********************************/
	if (projectModal.classList.contains('desktop') && !rightAlign) {
		// Large tablets
		if (lTabletMax.matches && lTabletMin.matches) {
			tlModal
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 200 },
					0
				)
				.fromTo(projectImage, time, { x: -169, y: 113 }, { x: 0, y: 0 }, 0)
		} else if (tabletMax.matches && tabletMin.matches) {
			console.log('small tablet')
			tlModal
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 60 },
					0
				)
				.fromTo(projectImage, time, { x: -178, y: 86 }, { x: 0, y: 0 }, 0)
		} else if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
			tlModal.fromTo(projectImage, time, { x: 25, y: 3 }, { x: 0, y: 0 }, 0)
			if (mobileMax.matches && mobileMin.matches) {
				tlModal.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height },
					0
				)
			} else {
				tlModal.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 100 },
					0
				)
			}
		} else {
			tlModal.from(
				projectImage,
				time,
				{
					top: imageRectTop,
					x: -67.5,
				},
				0
			)
		}
	}

	/********************************
	 * Desktop project right align
	 ********************************/
	if (rightAlign && projectModal.classList.contains('desktop')) {
		if (lTabletMax.matches && lTabletMin.matches) {
			tlModal
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 200 },
					0
				)
				.fromTo(
					projectImagesWrapper,
					time,
					{ paddingBottom: 0, marginLeft: 0, x: 0 },
					{ paddingBottom: 100, marginLeft: 0, xPercent: 50 },
					0
				)
				.from(projectImage, time, { top: imageRectTop, right: 21 }, 0)
		} else if (tabletMax.matches && tabletMin.matches) {
			console.log('tablet tight')
			tlModal
				.fromTo(
					projectIntro,
					time,
					{ height: divRect.height },
					{ height: divRect.height + 60 },
					0
				)
				.fromTo(projectImage, time, { x: -20, y: 86 }, { x: 0, y: 0 }, 0)
		} else if ((mobileMax.matches && mobileMin.matches) || sMobile.matches) {
			console.log('tablet mobile right')
			tlModal.fromTo(
				projectIntro,
				time,
				{ height: divRect.height },
				{ height: divRect.height },
				0
			)
			if (mobileMax.matches && mobileMin.matches) {
				tlModal.fromTo(projectImage, time, { x: -35, y: 4 }, { x: 0, y: 0 }, 0)
			} else {
				tlModal.fromTo(projectImage, time, { x: -30, y: 4 }, { x: 0, y: 0 }, 0)
			}
		} else {
			tlModal
				.fromTo(
					projectImagesWrapper,
					time,
					{ marginLeft: 0, x: 0 },
					{ marginLeft: 0, xPercent: 113 },
					0
				)
				.from(projectImage, time, { top: imageRectTop, right: 50 }, 0)
		}
	}
}
export default modalAnimations
