import React from 'react'
import Img from 'gatsby-image'

const Icons = ({ modalData }) => {
	if (modalData.childWordPressAcfCustomIconSet !== null) {
		return (
			<section className="container icons-content">
				<div className="row">
					<div className="col-md-6 chapter-info">
						<div className="description">
							<h2 className="reveal">
								{modalData.childWordPressAcfCustomIconSet.content.section_title}
							</h2>
							<div
								className="reveal"
								dangerouslySetInnerHTML={{
									__html: modalData.childWordPressAcfCustomIconSet.content.text,
								}}
							/>
						</div>
					</div>
					<div className="col-md-6 chapter-image">
						<Img
							fluid={
								modalData.childWordPressAcfCustomIconSet.content.icons_image
									.localFile.childImageSharp.fluid
							}
							alt={
								modalData.childWordPressAcfCustomIconSet.content.icons_image
									.alt_text
							}
						/>
					</div>
				</div>
			</section>
		)
	}
	return null
}

export default Icons
