import React from 'react'

const Text = ({ modalData }) => {
	if (modalData.childWordPressAcfJustText !== null) {
		return (
			<section className="container text-content">
				<div className="row">
					<div className="col-md-7 chapter-text">
						<h2 className="reveal">
							{modalData.childWordPressAcfJustText.section_title}
						</h2>
						<div
							className="reveal"
							dangerouslySetInnerHTML={{
								__html: modalData.childWordPressAcfJustText.section_text,
							}}
						/>
					</div>
				</div>
			</section>
		)
	}
	return null
}

export default Text
