import React from 'react'

// TODO LINK BUGS
const AboutProject = ({ signatureData, modalData }) => {
	if (modalData.acf.about_project !== null) {
		return (
			<section className="container about-project">
				<div className="row">
					<div className="col-md-12 about-wrapper">
						<div className="title">
							<h2>{modalData.acf.about_project.title}</h2>
							<span>{modalData.acf.about_project.subtitle}</span>
						</div>
						{modalData.acf.about_project.links.map(section => (
							<div key={section.link.title} className="links reveal">
								<a href={section.link.url} target="_blank" rel="noopener">
									{section.link.title}
								</a>
							</div>
						))}
						{modalData.tags !== null && (
							<div className="tags reveal">
								{modalData.tags.map(sectionTag => (
									<span key={sectionTag.id}>{sectionTag.name}</span>
								))}
							</div>
						)}
					</div>
				</div>
			</section>
		)
	}
	return null
}

export default AboutProject
