import React, { Component } from 'react'
import { connect } from 'react-redux'
import { animationStatus } from '../actions/animationAction'
import Layout from './layout'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { Link, navigate } from 'gatsby'

import Logo from '../images/logo.svg'
import Projects from './projects'

class ConditionalModal extends Component {
	state = {
		reverse: false,
	}

	onProjectClose = e => {
		document.body.classList.remove('no-scroll')
		//window.scrollTo(0, offset)
	}

	onModalClose = scrollLocation => {
		const page = document.getElementsByClassName('project-modal')[0]
		//	position absolute
		const position = page.scrollTop
		if (position > 3500) {
			setTimeout(
				() =>
					navigate(scrollLocation, {
						scrollRestoration: 'auto',
						state: { noScroll: true },
					}),
				1700
			)
		} else {
			setTimeout(
				() =>
					navigate(scrollLocation, {
						scrollRestoration: 'auto',
						state: { noScroll: true },
					}),
				1100
			)
		}

		document.body.classList.remove('no-scroll')
		const animeStatus = {
			play: false,
			reverse: true,
		}
		this.props.animationStatus(animeStatus)
	}

	onModalCloseLogo = () => {
		document.body.removeAttribute('style')
		document.body.classList.remove('no-scroll')
	}

	render() {
		const { children, ...rest } = this.props

		if (this.state.reverse === true) return null
		return (
			<ModalRoutingContext.Consumer>
				{({ modal, closeTo }) => {
					const closeToSingle = '/projects'
					return modal ? (
						<React.Fragment>
							<nav id="nav" className="modal-navigation">
								<div className="container">
									<div className="row">
										<Link
											to="/"
											className="brand"
											onClick={this.onModalCloseLogo}
											state={{
												noScroll: true,
											}}
										>
											<Logo />
										</Link>
										<button
											className="md-close"
											onClick={() => this.onModalClose(closeTo)}
										>
											back to showcase
										</button>
									</div>
								</div>
							</nav>
							{children}
						</React.Fragment>
					) : (
						<Layout {...rest}>
							<Projects />
							<nav className="modal-navigation">
								<div className="container">
									<div className="row">
										<Link
											to="/"
											className="brand"
											onClick={this.onProjectClose}
											state={{
												noScroll: true,
											}}
										>
											<Logo />
										</Link>
										<button
											className="md-close"
											//	onClick={() => this.onModalClose(closeToSingle)}
											onClick={() => this.onModalClose(closeToSingle)}
										>
											back to showcase
										</button>
									</div>
								</div>
							</nav>
							{children}
						</Layout>
					)
				}}
			</ModalRoutingContext.Consumer>
		)
	}
}

export default connect(
	null,
	{
		animationStatus,
	}
)(ConditionalModal)
